<template>
  <form
      v-if="box.length"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.name"
              label="Name"
              name="name"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.email"
              label="E-mail"
              name="email"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
              v-model="form.address_1"
              label="Address Line 1"
              name="address-1"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
              v-model="form.address_2"
              label="Address Line 2"
              name="address-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
              v-model="form.city"
              label="City"
              name="city"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
              v-model="form.state"
              label="State"
              name="state"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
              v-model="form.zip"
              label="Zip"
              name="zip"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
              v-model="form.country"
              :items="countries"
              label="Country"
              name="country"
              disabled
              required
          ></v-select>
          <p class="error--text">We currently only ship to the US using our automated system</p>
        </v-col>
      </v-row>
      <v-btn @click="shipThem">Ship Them</v-btn>
    </v-container>
  </form>
</template>

<script>
import {mapGetters} from 'vuex';
import {$wax} from '@/services/wax';
import axios from 'axios';

export default {
  data: function () {
    return {
      form: {
        name: '',
        email: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
      },
      countries: [
        'US',
      ],
    };
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('product', ['box']),
    userName() {
      return this.user.name;
    },
  },
  methods: {
    encode(data) {
      return Object.keys(data)
          .map(
              key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
          )
          .join('&');
    },
    shipThem() {
      const asset_ids = this.box.map((asset) => asset.asset_id);

      $wax.transferAssets(asset_ids, this.user.wax, 'sandgtraders', this.box[0].contract).then((res) => {
        if (res.processed.receipt.status === 'executed') {
          this.$store.dispatch('product/emptyBox');
          axios.post(
              '/',
              this.encode({
                'form-name': 'shipping-order',
                'wallet': this.user.name,
                'asset_ids': asset_ids.join(','),
                'transaction': res.transaction_id,
                ...this.form,
              }),
              {
                header: {'Content-Type': 'application/x-www-form-urlencoded'},
              },
          );
        }
        console.log(res);
      });
    },
  },
};
</script>
