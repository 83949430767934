<template>
  <div>
    <Hero/>
    <InventoryCards />
  </div>
</template>

<script>
import Hero from '../components/local/InventoryHero.vue'
import InventoryCards from '../components/local/InventoryCards'

export default {
  components: {
    Hero,
    InventoryCards
  }
}
</script>
