<template>
  <v-col
    cols="12"
    sm="6"
    lg="4"
    xl="3"
    class="text-center d-flex flex-column"
  >
    <v-img :src="asset.data.img | ipfsUrl" height="440" position="center 30%" class="rounded elevation-2"></v-img>
    <v-sheet class="pa-3 mx-2 mt-n6 position-relative flex-grow-1" elevation="2" rounded color="surface">
      <div class="text-h5">{{ asset.data.name }}</div>
      <div class="text-h6 secondary--text">{{ asset.data.description }}</div>
      <v-btn
        v-if="!inBox"
        color="primary"
        class="mt-4 background--text"
        @click="addToShippingBox"
      >
        Add To ShipBox
      </v-btn>
      <v-btn
        v-if="inBox"
        class="mt-4"
        @click="removeFromShippingBox"
      >
        Remove From Box
      </v-btn>
    </v-sheet>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    asset: {}
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('product', ['box']),
    inBox() {
      return this.box.filter((bItem) => {
        return bItem.asset_id === this.asset.asset_id
      }).length > 0
    }
  },
  methods: {
    addToShippingBox() {
      this.$store.dispatch('product/addToBox', { asset: this.asset })
    },
    removeFromShippingBox() {
      this.$store.dispatch('product/removeFromBox', { asset: this.asset })
    }
  }

}
</script>
