<template>
  <v-container class="py-4 py-lg-8">
    <v-row v-if="box.length" class="">
      <ShippingBoxCard
        v-for="asset in box"
        :key="asset.asset_id"
        :asset="asset"
      />
    </v-row>
    <v-row v-else>
      <h2>No Coins In Your Shipping Box Add Some From
        <router-link :to="{name: 'Inventory'}">Your Inventory</router-link>
      </h2>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ShippingBoxCard from '@/components/local/ShippingBoxCard'

export default {
  components: {
    ShippingBoxCard
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('product', ['box']),
    userName() {
      return this.user.name
    }
  },
  watch: {
    userName: function () {
      this.$store.dispatch('account/getUserInventory')
    }
  },
  mounted() {
    if (this.userName) this.$store.dispatch('account/getUserInventory')
  }

}
</script>
