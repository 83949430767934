async function transferAssets(assetIds, wax, toAddress, account) {
  return await wax.api.transact({
    actions: [{
      account: account,
      name: 'transfer',
      authorization: [{
        actor: wax.userAccount,
        permission: 'active'
      }],
      data: {
        from: wax.userAccount,
        to: toAddress,
        asset_ids: assetIds,
        memo: ''
      }
    }]
  }, {
    blocksBehind: 3,
    expireSeconds: 1200
  })
}

export const $wax = {
  transferAssets
}
