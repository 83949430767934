<template>
  <div>
    <Hero/>
    <OurSystem/>
    <Benefits />
  </div>
</template>

<script>
import Hero from '../components/local/HomeHero.vue'
import OurSystem from '../components/local/OurSystem'
import Benefits from '../components/local/Benifits'

export default {
  components: {
    Hero,
    OurSystem,
    Benefits
  }
}
</script>
