<template>
  <div>
    <Hero/>
    <BoxCards/>
    <ShippingBoxForm/>
  </div>
</template>

<script>
import Hero from '../components/local/ShippingBoxHero.vue'
import BoxCards from '../components/local/ShippingBoxCards'
import ShippingBoxForm from '@/components/local/ShippingBoxForm'

export default {
  components: {
    Hero,
    BoxCards,
    ShippingBoxForm
  }
}
</script>
