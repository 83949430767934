<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row class="">
        <InventoryCard
          v-for="asset in user.assets"
          :key="asset.asset_id"
          :asset="asset"
        />
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import InventoryCard from '@/components/local/InventoryCard'

export default {
  components: {
    InventoryCard
  },
  computed: {
    ...mapGetters('account', ['user']),
    userName() {
      return this.user.name
    }
  },
  watch: {
    userName: function () {
      this.$store.dispatch('account/getUserInventory')
    }
  },
  mounted() {
    if (this.userName) this.$store.dispatch('account/getUserInventory')
  }

}
</script>
