<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8 mt-6">
      <v-row>
        <v-col cols="12" md="4">
          <h2 class="text-h3 text-lg-h2"><span class="primary--text">S&G</span> Is Changing The Way We Buy, Trade, and Sell Coins</h2>
        </v-col>
        <v-col cols="12" md="8" class="pa-md-0">
          <v-row>
            <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
              <div class="d-flex">
                <div class="mr-2">
                  <v-sheet color="surface" rounded class="pa-2">
                    <v-icon size="34" color="primary">{{ item.icon }}</v-icon>
                  </v-sheet>
                </div>
                <div>
                  <div class="text-h5">{{ item.title }}</div>
                  <div class="text-h6 mt-1">{{ item.description }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: '$vuetify.icons.buy',
        title: 'Buy',
        description: 'Buy an N&G NFT Coin using WAX and receive your unique authenticated crypto token that you can store in your online or offline wallet.'
      }, {
        icon: '$vuetify.icons.trade',
        title: 'Trade',
        description: 'Trade your Coin with other collectors for any other NFTs, from Topps Baseball Cards, prices of art, to other N&G NFT Coins.'
      }, {
        icon: '$vuetify.icons.sell',
        title: 'Sell',
        description: 'Sell your Coins on the open market to 1000s of buyers. List your coins for any price in a buy it now sale, auction, or any other format you choose on any of many 3rd party marketplaces.'
      }, {
        icon: '$vuetify.icons.convert',
        title: 'Convert',
        description: ' Convert your NFT at anytime to it’s real world Coin. When you are ready simple fill out a form with your information and send us the NFT and we will send you the coin immediately.'
      }]
    }
  }
}
</script>
