<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8 mt-6">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">The Benefits Of NFT Coins</div>
        <h2 class="text-h3 text-lg-h2">S&G Coins Allow For Secure, Simple, and Liquid Coin Collection</h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">With NFTs Back By Real Coins you have the security of real silver and gold, without all the hassle. Buy and sell from individuals quickly and securely online.</div>
        </v-responsive>
      </div>
      <v-row class="mt-6">
        <v-col cols="12" lg="6" xl="7">
          <h2 class="text-h3 text-lg-h2">What Makes NFT Coins Better?</h2>
          <div v-for="(item, i) in features" :key="i" class="d-flex mt-5">
            <div class="mr-2">
              <v-sheet color="surface" rounded class="pa-2" dark>
                <v-icon size="34" color="primary">{{ item.icon }}</v-icon>
              </v-sheet>
            </div>
            <div>
              <div class="text-h5">{{ item.title }}</div>
              <div class="text-h6 mt-1">{{ item.description }}</div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6" xl="5">
          <v-img
            class="rounded-lg mt-2"
            max-height="1000"
            contain
            :src="require('@/assets/images/financial16.svg')"
            alt=""
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-account-check-outline',
        title: 'Secure',
        description: 'Using the blockchain to cryptographically secure each S&G NFT stops anyone from counterfeiting, stealing, or destroying your investment.'
      }, {
        icon: 'mdi-lifebuoy',
        title: 'Simple',
        description: 'Each NFT is easily verifiable, transferable, and viewable saving you any of the hassles that come with physical coins.'
      }, {
        icon: 'mdi-clock-outline',
        title: 'Liquid',
        description: 'Quickly buy or sell your NFT Coins on any of the NFT markets such as AtomicHub which is doing over $1 million in sales every day.'
      }]
    }
  }
}
</script>
